

body { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  body { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  overflow-x:hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-avatar.ant-avatar > img{
  height:auto;
}

.ant-card-head-title.ant-card-head-title{
  white-space: initial;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item-active.ant-menu-item-active:active:not(.ant-menu-item-selected), /* Menu item on active */
.ant-menu-item-active.ant-menu-item-active:active:not(.ant-menu-item-selected) .ant-typography, /* Dropdown item on active */
.ant-menu-submenu-title:active{
  color:white;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td{
  background:#EEE !important;
}